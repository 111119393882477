import * as React from "react";
import { useMemo, useEffect, useState } from "react";
import { IconButton, Input } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import debounce from "lodash/debounce";

const AddOrRemoveButton = (props: { adder: boolean; onClick: () => void }) => {
  return (
    <IconButton
      sx={{
        backgroundColor: "background.default",
      }}
      onClick={() => {
        props.onClick();
      }}
      disableRipple
    >
      {props.adder ? (
        <AddIcon data-test="add" />
      ) : (
        <RemoveIcon data-test="remove" />
      )}
    </IconButton>
  );
};

const ItemCounter = (props: {
  count: number;
  onChange: (newCount: number) => void;
}) => {
  const [count, setCount] = useState(props.count);

  const checkInput = () => {
    setCount((prevState) => (prevState > 1 ? prevState : 1));
  };
  const debounceInputChange = useMemo(() => debounce(checkInput, 1000), []);

  useEffect(() => {
    return () => {
      debounceInputChange.cancel();
    };
  }, []);

  useEffect(() => {
    if (!isNaN(parseInt(count))) props.onChange(count);
  }, [count]);

  const incrementCount = (increment: number) => {
    setCount((oldCount) =>
      oldCount + increment > 1 ? oldCount + increment : 1
    );
  };

  useEffect(() => {
    setCount(props.count);
  }, [props.count]);

  return (
    <>
      <AddOrRemoveButton adder={false} onClick={() => incrementCount(-1)} />
      <Input
        disableUnderline
        value={count}
        type="number"
        sx={{
          width: "52px",
          border: "1px solid #F0F0F0",
          borderRadius: 0.5,
          p: 0,
        }}
        inputProps={{
          style: { textAlign: "center" },
          min: 1,
        }}
        onChange={(e) => {
          setCount(
            isNaN(parseInt(e.target.value)) ? "" : parseInt(e.target.value)
          );
          debounceInputChange();
        }}
      />
      <AddOrRemoveButton adder onClick={() => incrementCount(1)} />
    </>
  );
};

export default ItemCounter;
