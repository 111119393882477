import * as React from "react";
import { Button, Card, CardContent, Stack, Typography } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useState, useContext } from "react";
import CartContext from "../context/CartContext";
import ItemCounter from "./ItemCounter";

const ItemCard = (props: {
  item: { name: string; imagePath: string; price: number };
}) => {
  const { addItemToCart } = useContext(CartContext);
  const [count, setCount] = useState(1);

  const addToCart = () => {
    addItemToCart({
      name: props.item.name,
      pricePerOne: props.item.price,
      count,
      note: "",
    });
    setCount(1);
  };

  return (
    <Card
      key={props.item.name}
      sx={{
        padding: 1.5,
      }}
    >
      <CardContent
        sx={{
          p: 0,
        }}
      >
        <Stack direction="row" spacing={1.5}>
          <img
            style={{
              maxWidth: "100px",
              maxHeight: "100px",
            }}
            src={`https://${props.item.imagePath}`}
            alt="Paella dish"
          />
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={0.5}
          >
            <Typography variant="subtitle2">{props.item.name}</Typography>
            <Typography variant="body1">
              {new Intl.NumberFormat("cs-CZ", {
                style: "currency",
                currency: "CZK",
                maximumFractionDigits: 0,
              }).format(props.item.price * count)}
              /{count == 1 ? "" : count}ks
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="stretch"
        gap={1}
        sx={{
          marginTop: 1.5,
          height: "40px",
          width: "100%",
        }}
      >
        <ItemCounter
          count={count}
          onChange={(newCount) => setCount(newCount)}
        />
        <Button
          variant="contained"
          startIcon={<ShoppingCartIcon />}
          disableElevation
          onClick={addToCart}
          sx={{
            marginLeft: "auto",
          }}
          data-test="addButton"
        >
          Přidat
        </Button>
      </Stack>
    </Card>
  );
};

export default ItemCard;
