import React, { useContext } from "react";
import { Container, Stack, Typography, CircularProgress } from "@mui/material";
import ItemCard from "../components/ItemCard";
import CartFoot from "../components/CartFoot";
import CartContext from "../context/CartContext";
import CategoryContext from "../context/CategoryContext";
import { useProducts } from "../hooks/queryHooks";

const Index = () => {
  const { currentCategory } = useContext(CategoryContext);

  const { isSomeItems } = useContext(CartContext);

  const { data, isLoading, isSuccess } = useProducts(
    String(currentCategory?.id ?? -1)
  );

  return (
    <>
      <Container
        sx={{
          mb: isSomeItems ? "80px" : "5px",
        }}
      >
        <Typography
          variant="h6"
          component="div"
          sx={{
            mx: 1.5,
            marginBottom: 1,
            marginTop: 2,
          }}
        >
          {currentCategory?.name ?? ""}
        </Typography>
        <Stack
          direction="row"
          justifyContent="center"
          sx={{
            flexWrap: "wrap",
          }}
          gap={1.5}
        >
          {isLoading && <CircularProgress />}
          {isSuccess && data.length === 0 && (
            <Typography variant="h6">There are no items</Typography>
          )}
          {data && data.map((item) => <ItemCard item={item} key={item.name} />)}
        </Stack>
        {isSomeItems && <CartFoot buttonText="Do košíku" buttonUrl="/order" />}
      </Container>
    </>
  );
};

export default Index;
